import { memo, MouseEvent } from 'react';
import { CeBrandNavigationEvent, CeNavigationEvent, CeNavigationHierarchy } from '~/features/tracking/model';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';
import ChevronDownIcon from '~/icons/chevron-down.svg';
import IMainMenuNavigationLink from './MainMenuNavigationLink.def';
import { StyledIcon, StyledMainMenuNavigationLink } from './MainMenuNavigationLink.styled';

const MainMenuNavigationLink = memo(({ text, linkState, isAccordion, isSingleLine, isSubMenu, tracking, onClick, ...linkProps }: IMainMenuNavigationLink) => {
	const transformTrackingData = (data: CeNavigationHierarchy) => {
		return Object.fromEntries(Object.entries(data).map(([k, v]) => [k, v.toLowerCase()])) as CeNavigationHierarchy;
	};

	const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
		if (tracking.isBrand) {
			pushToDataLayer<CeBrandNavigationEvent>({
				event: 'brand_menu_click',
				...transformTrackingData(tracking.hierarchy),
			});
		} else {
			pushToDataLayer<CeNavigationEvent>({
				event: 'menu_click',
				secondLevel: '',
				thirdLevel: '',
				fourthLevel: '',
				...transformTrackingData(tracking.hierarchy),
			});
		}

		onClick?.(e);
	};

	return (
		<StyledMainMenuNavigationLink
			{...linkProps}
			data-text={text}
			linkState={linkState}
			isSingleLine={isSingleLine}
			isSubMenu={isSubMenu}
			isCustomStyle
			onClick={handleClick}
		>
			{text}

			{isAccordion ? (
				<StyledIcon isActive={linkState === 'active'}>
					<ChevronDownIcon />
				</StyledIcon>
			) : null}
		</StyledMainMenuNavigationLink>
	);
});

export default MainMenuNavigationLink;
