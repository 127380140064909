import { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { IVideoMedia } from '~/lib/data-contract';
import { VideoMedia } from '~/shared/components/VideoMedia/VideoMedia';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { ProductCardInnerProps } from '../../ProductCard';
import { StyledThumbnailInner } from '../Thumbnail/styled';

const isSrcEl = (el: Element): el is HTMLSourceElement => {
	return el.tagName === 'SOURCE';
};

export type ProductVideoProps = {
	video: IVideoMedia;
	poster?: string;
	background?: ProductCardInnerProps['background'];
};

export const ProductVideo = ({ video, poster, background }: ProductVideoProps) => {
	const [loaded, setLoaded] = useState(false);

	const ref = useRef<HTMLVideoElement>(null);
	const observer = useIntersection(ref, {
		threshold: 0.6,
	});

	useEffect(() => {
		if (isSSR || !ref.current || !loaded) {
			return;
		}

		if (observer?.isIntersecting) {
			ref.current.play();
		} else {
			ref.current.pause();
		}
	}, [observer?.isIntersecting, loaded]);

	useEffect(() => {
		if (isSSR || !ref.current) {
			return;
		}

		if (observer?.isIntersecting && !loaded) {
			for (const srcEl of Array.from(ref.current.children)) {
				if (isSrcEl(srcEl) && srcEl.dataset.src) {
					srcEl.src = srcEl.dataset.src;
				}
			}

			ref.current.load();
			setLoaded(true);
		}
	}, [observer?.isIntersecting]);

	if (poster && !video.external.previewImageUrl) {
		video.external.previewImageUrl = poster;
	}

	return (
		<StyledThumbnailInner
			background={background}
			isHidden={!loaded}
		>
			<VideoMedia
				ref={ref}
				video={{ ...video, autoPlay: true, loop: true } as IVideoMedia}
				sourceAsDataAttr
			/>
		</StyledThumbnailInner>
	);
};
