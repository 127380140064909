import { AccordionItem } from '@radix-ui/react-accordion';
import { memo, MouseEvent as MouseEventReact } from 'react';
import { StyledAccordionContainer } from '~/shared/components/Accordion/components/AccordionItem/styled';
import useMainMenuState from './../../../../useMainMenuState';
import { StyledMainMenuAccordionTrigger } from './../../MainMenuNavigation.styled';
import MainMenuNavigationLink from './../../MainMenuNavigationLink/MainMenuNavigationLink';
import IMainMenuNavigationLink from './../../MainMenuNavigationLink/MainMenuNavigationLink.def';
import NavigationSecondary from './../../NavigationSecondary/NavigationSecondary';
import INavigationMainItem from './NavigationMainItem.def';
import { StyledNavigationMainItem } from './NavigationMainItem.styled';

const NavigationMainItem = memo(({ id, link, state, defaultSecondaryMenu, subListNodes, useAccordion, isSingleLine, hasMetaHeader, currentVertical }: INavigationMainItem) => {
	const { menuIsOpen, activeItemMain } = useMainMenuState((menuState) => ({
		menuIsOpen: menuState.menuIsOpen,
		activeItemMain: menuState.activeItemMain,
	}));
	const setActiveItemMain = useMainMenuState((menuState) => menuState.setActiveItemMain);

	const { url: linkUrl, text: linkText, ...linkProps } = link;

	const hasSubNavList = !!subListNodes?.length;

	const handleHover = () => {
		setActiveItemMain(id);
	};

	const handleClick = (event: MouseEventReact<HTMLAnchorElement>) => {
		setActiveItemMain(activeItemMain === id ? null : id);

		// Ensure click on AccordionTrigger while avoiding redirect to anchor href
		event.currentTarget.parentElement?.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
			}),
		);

		const targetBrandId = event.currentTarget.id;
		const targetIsRDG = Number(targetBrandId) < 0;
		const RDGTargetOnRDG = targetIsRDG && !currentVertical;
		const SameBrandTarget = targetBrandId === currentVertical?.id;

		if (RDGTargetOnRDG || SameBrandTarget) {
			event.preventDefault();
		} else {
			window.sessionStorage.setItem('navigationMenuKeepActive', 'true');
		}
	};

	const navigationLinkProps: IMainMenuNavigationLink = {
		...linkProps,
		href: linkUrl,
		text: linkText,
		linkState: state,
		isSingleLine,
		tracking: {
			isBrand: true,
			hierarchy: {
				firstLevel: linkText,
			},
		},
	};

	return useAccordion && hasSubNavList ? (
		<AccordionItem
			value={id}
			asChild
		>
			<StyledNavigationMainItem>
				<StyledMainMenuAccordionTrigger>
					<MainMenuNavigationLink
						{...navigationLinkProps}
						onClick={handleClick}
						isAccordion
					/>
				</StyledMainMenuAccordionTrigger>

				<StyledAccordionContainer>
					<NavigationSecondary
						nodes={subListNodes}
						hasMetaHeader={hasMetaHeader}
						useAccordion
					/>
				</StyledAccordionContainer>
			</StyledNavigationMainItem>
		</AccordionItem>
	) : (
		<StyledNavigationMainItem isDefaultSecondaryMenu={defaultSecondaryMenu}>
			<MainMenuNavigationLink
				{...navigationLinkProps}
				onMouseEnter={!menuIsOpen ? handleHover : undefined}
			/>

			{hasSubNavList ? (
				<NavigationSecondary
					nodes={subListNodes}
					isOpen={state === 'active' || !activeItemMain && defaultSecondaryMenu}
					hasMetaHeader={hasMetaHeader}
				/>
			) : null}
		</StyledNavigationMainItem>
	);
});

export default NavigationMainItem;
