import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { breakpoints } from '~/theme';

export const StyledVideoMediaContainer = styled.div({
	position: 'relative',
	width: '100%',
	height: '100%',
});

type ObjectFit = 'cover' | 'contain';

interface VideoMediaPlayerProps {
	objectFit?: ObjectFit;
}

export const StyledVideoMediaPlayer = styled.video<VideoMediaPlayerProps>(({ objectFit = 'cover' }) => ({
	objectFit: objectFit,
	width: '100%',
	height: '100%',
	[breakpoints.md]: {
		objectFit: 'cover',
	},
}));

export const StyledVideoMediaControls = styled(StyledResetButton)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
});

export type StyledVideoMediaControlProps = {
	hidden?: boolean;
};

export const StyledVideoMediaControl = styled.div<StyledVideoMediaControlProps>(
	({ theme }) => ({
		...theme.textStyles.bodySm,
		width: theme.space[10],
		height: theme.space[10],
		borderRadius: `calc(${theme.space[10]} * 0.5)`,
		border: `1px solid ${theme.colors.light}`,
		background: theme.colors.dark + '33',
		textTransform: 'uppercase',
		color: theme.colors.light,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transition: `opacity ${theme.animations.timingShort} ${theme.animations.easeSmooth}`,
	}),
	ifProp('hidden', {
		opacity: 0,
	}),
);
