import { DetailedHTMLProps, forwardRef, useRef, useState, VideoHTMLAttributes } from 'react';
import { IVideoMedia } from '~/lib/data-contract';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { useVimeoFile } from './hooks/useVimeoFile';
import { StyledVideoMediaContainer, StyledVideoMediaControl, StyledVideoMediaControls, StyledVideoMediaPlayer } from './styled';

export type VideoMediaProps = VideoHTMLAttributes<HTMLVideoElement> & {
	video: IVideoMedia;
	onChangeVideoPlaying?: (value: boolean) => void;
	objectFit?: string;
	/**
	 * if true, src will be added as data-src. Useful for lazy loading behaviour.
	 */
	sourceAsDataAttr?: boolean;
	showMediaControls?: boolean;
};

export const VideoMedia = forwardRef<HTMLVideoElement, VideoMediaProps>(({ video, showMediaControls, onChangeVideoPlaying, sourceAsDataAttr, ...nextVideoProps }, ref) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const file = useVimeoFile(video?.external?.files);
	const { translate } = useTranslation();
	const [playing, setPlaying] = useState(false);
	const [hovering, setHovering] = useState(false);

	if (!file) {
		// no video to show
		return null;
	}

	const handleClick = () => {
		if (playing) {
			videoRef.current?.pause();
		} else {
			videoRef.current?.play();
			setHovering(false);
		}
	};

	const handlePlay = () => {
		setPlaying(true);
		onChangeVideoPlaying && onChangeVideoPlaying(true);
	};

	const handlePause = () => {
		setPlaying(false);
		onChangeVideoPlaying && onChangeVideoPlaying(false);
	};

	const isLooping = video.loop;
	const isAutoPlay = video.autoPlay;
	const isHidingControls = !hovering && playing;

	const videoProps: DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> = {
		ref: ref || videoRef,
		loop: isLooping,
		onPause: handlePause,
		onEnded: handlePause,
		onPlay: handlePlay,
		autoPlay: isAutoPlay,
		muted: isAutoPlay,
		poster: video.external.previewImageUrl,
		playsInline: true,
		...nextVideoProps,
	};

	const sourceProps: React.DetailedHTMLProps<React.SourceHTMLAttributes<HTMLSourceElement>, HTMLSourceElement> & {
		'data-src'?: string;
	} = {};

	// Add dnt=1 as a query for vimeo tracking (RDG-2836)
	const videoUrl = buildURL(file.link, { dnt: '1' });
	if (sourceAsDataAttr) {
		sourceProps['data-src'] = videoUrl;
	} else {
		sourceProps.src = videoUrl;
	}

	return (
		<StyledVideoMediaContainer>
			<StyledVideoMediaPlayer {...videoProps}>
				<source {...sourceProps} />
			</StyledVideoMediaPlayer>
			{!isAutoPlay || showMediaControls ? (
				<StyledVideoMediaControls
					onClick={handleClick}
					onMouseEnter={() => setHovering(true)}
					onMouseLeave={() => setHovering(false)}
				>
					<StyledVideoMediaControl hidden={isHidingControls}>{playing ? translate('video-player.pause') : translate('video-player.play')}</StyledVideoMediaControl>
				</StyledVideoMediaControls>
			) : null}
		</StyledVideoMediaContainer>
	);
});
