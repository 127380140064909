import { IMarket } from '~/shared/utils/market/types';
import { postRequest } from '~/shared/utils/request/utils/postRequest';
import { BASKET_ABANDONED_CREATE_OR_UPDATE } from './endpoints';

const createOrUpdateAbandonedBasket = async (basketReference: string, itemKeyValuePair: { key: string; value: number }[], market: IMarket) => {
	if (!basketReference) {
		return;
	}

	const request = postRequest<string>(BASKET_ABANDONED_CREATE_OR_UPDATE, {
		...market,
		basketReference,
		itemKeyValuePair,
	});

	return await request();
};

export default createOrUpdateAbandonedBasket;
